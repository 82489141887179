import React from 'react';
import './Card.css';
import card1 from '../../Assesst/Images/pro1.jpg';
import card2 from '../../Assesst/Images/pro2.jpg'; 
import card3 from '../../Assesst/Images/pro3.jpg'; 
import card4 from '../../Assesst/Images/pro4.jpg'; 

const cardData = [
  {
    id: 1,
    image: card1,
    title: 'CCTV Camera',
    text: 'Closed-circuit television is the use of video cameras to transmit a signal to a specific place, on a limited se',
    link: '#a'
  },
  {
    id: 2,
    image: card2,
    title: 'Security System',
    text: 'A security system is a system designed to detect intrusion – unauthorized entry – into a building or other area.',
    link: '#b'
  },
  {
    id: 3,
    image: card3,
    title: 'Security System',
    text: 'A security system is a system designed to detect intrusion – unauthorized entry – into a building or other area.',
    link: '#b'
  },
  {
    id: 4,
    image: card4,
    title: 'Security System',
    text: 'A security system is a system designed to detect intrusion – unauthorized entry – into a building or other area.',
    link: '#b'
  },
];

function Card() {
  return (
    <>
      <h2 className='digital-card-heading hover-border'>
        <span className='digital-card-headingspan'>Our</span> Product
      </h2>
      <div className="card-container">
        {cardData.map(card => (
          <div className="card" key={card.id}>
            <img src={card.image} className="card-img-top" alt={card.title}></img>
            <div className="card-body">
              <h5 className="card-title">{card.title}</h5>
              <p className="card-text">{card.text}</p>
              <a href={card.link} className="btn btn-danger">Read</a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Card;
