import Carousel from "../../Component/Carousel/Carousel";
import Navbar2 from "../../Component/GlobleComponent/Navbar/Navbar2";
import Navbar from "../../Component/Navbar";
import Image from "../../Assesst/Images/2.jpg";
import Image1 from "../../Assesst/Images/3.gif";
import Image2 from "../../Assesst/Images/banner.jpg";
import Footer from "../Footer/Footer";
import ContactCard from "../ContactCard/ContactCard";
import FaqSection from "../FaqSection/FaqSection";
import StatsSection from "../StatsSection/StatsSection";
import HomeContentSection1 from "../HomeContentSection1/HomeContentSection1";
import Card from "../Cards/Card";
import Sidebar from "../Sidebar/Sidebar";

function Home() {
  const items = [
    {
      image: Image,
      text: "Video Conferencing",
    },
    {
      image: Image1,
      text: "VoIP EPABX",
    },
    {
      image: Image2,
      text: "Biometrics & VDP",
    },
  ];

  return (
    <>
      <Navbar />
      <Sidebar />
      <Navbar2 />
      <Carousel items={items} />
      <Card />
      <StatsSection />
      <HomeContentSection1 />
      <ContactCard />
      <FaqSection />
      <Footer />
    </>
  );
}

export default Home;
