import React, { useState } from 'react';
import './FaqSection.css';
import icon4 from '../../Assesst/Images/1.gif';

const faqs = [
  {
    question: 'Where can articles lost in the bus be claimed?',
    answer: 'From Our Lost Property Section, Wadala Depot, after paying necessary charges.',
  },
  {
    question: 'From where can ticket balance amount be collected?',
    answer: 'You can collect the ticket balance amount from our customer service centers.',
  },
  {
    question: 'How BEST buses can be reserved?',
    answer: 'BEST buses can be reserved through our online reservation system or by contacting our reservation offices.',
  },
  {
    question: 'How are complaints redressed?',
    answer: 'Complaints are redressed through our customer service centers and online complaint system.',
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <div className="faq-left">
        <h4>FAQ'S</h4>
        <h1>General <span className="highlight-text2">Frequently</span> Asked Questions</h1>
        <p>
          The undertaking has been fully conscious of the alarming levels of pollution in
          cities. With modern and reliable technology, the Undertaking has been making
          constant efforts not only to keep pollution level under control, but minimise it.
        </p>
        <img src={icon4} alt="" className="faq-image" />
      </div>
      <div className="faq-right">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <div className="faq-question-icon">❓</div>
              <p>{faq.question}</p>
              <div className="faq-toggle-icon">{activeIndex === index ? '▲' : '▼'}</div>
            </div>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
