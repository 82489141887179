// Navbar2.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar2.css';
import Image from "../../../Assesst/Images/logo.png";

const Navbar2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar-left">
          <img src={Image} alt="Logo" className="navbar-logo" />
        </div>
        <div className="navbar-center">
          <Link to="/" className="navbar-link">Home</Link>
          <div className="navbar-dropdown">
            <Link to="/product" className="navbar-link">Product <i className="fas fa-angle-down"></i></Link>
            <div className="dropdown-content">
              <Link to="/profile/overview">Overview</Link>
              <Link to="/profile/settings">Settings</Link>
            </div>
          </div>
          <div className="navbar-dropdown">
            <Link to="/brochure" className="navbar-link">Brochure <i className="fas fa-angle-down"></i></Link>
            <div className="dropdown-content">
              <Link to="/media/photos">Photos</Link>
              <Link to="/media/videos">Videos</Link>
            </div>
          </div>
          <Link to="/about-us" className="navbar-link">About Us</Link>
          <Link to="/client" className="navbar-link">Client</Link>
          <Link to="/contact" className="navbar-link">Contact Us</Link>
        </div>
        <div className="navbar-right">
          <button className="employee-portal-button">Employee Portal</button>
          <button className="menu-button" onClick={toggleMenu}>
            {isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
          </button>
        </div>
      </div>

      <div className={`offcanvas-menu ${isOpen ? 'open' : ''}`}>
        <Link to="/" className="navbar-link" onClick={toggleMenu}>Home</Link>
        <div className="navbar-dropdown">
          <Link to="/profile" className="navbar-link" onClick={toggleMenu}>Profile <i className="fas fa-angle-down"></i></Link>
          <div className="dropdown-content">
            <Link to="/profile/overview" onClick={toggleMenu}>Overview</Link>
            <Link to="/profile/settings" onClick={toggleMenu}>Settings</Link>
          </div>
        </div>
        <div className="navbar-dropdown">
          <Link to="/media" className="navbar-link" onClick={toggleMenu}>Media <i className="fas fa-angle-down"></i></Link>
          <div className="dropdown-content">
            <Link to="/media/photos" onClick={toggleMenu}>Photos</Link>
            <Link to="/media/videos" onClick={toggleMenu}>Videos</Link>
          </div>
        </div>
        <Link to="/tender" className="navbar-link" onClick={toggleMenu}>Tender</Link>
        <Link to="/recruitments" className="navbar-link" onClick={toggleMenu}>Recruitments</Link>
        <Link to="/contact" className="navbar-link" onClick={toggleMenu}>Contact</Link>
      </div>
    </>
  );
}

export default Navbar2;
