import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "./AboutUs.css"; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-us-page">
      {/* Banner Section */}
      <div className="banner">
        <h1>About Us</h1>
      </div>

      {/* Content Section */}
      <Container className="mt-5">
        <Row className="mb-4">
          <Col md={6} xs={12}>
            <Image
              src="/path/to/your/camera-image.jpg"
              fluid
              className="content-image"
            />
          </Col>
          <Col md={6} xs={12}>
            <h2>Our Story</h2>
            <p>
              Welcome to our camera shop! We have been in the business of
              providing top-quality cameras and accessories for over a decade.
              Our passion for photography drives us to deliver the best products
              and services to our customers.
            </p>
            <Button variant="danger" className="mt-3">
              Learn More
            </Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6} xs={12} className="order-md-2">
            <Image
              src="/path/to/another-camera-image.jpg"
              fluid
              className="content-image"
            />
          </Col>
          <Col md={6} xs={12} className="order-md-1">
            <h2>Our Mission</h2>
            <p>
              Our mission is to empower photographers of all levels to capture
              their best moments with the finest equipment. We believe in
              providing reliable and innovative products that enhance the art of
              photography.
            </p>
            <Button variant="danger" className="mt-3">
              Learn More
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12}>
            <Image
              src="/path/to/team-image.jpg"
              fluid
              className="content-image"
            />
          </Col>
          <Col md={6} xs={12}>
            <h2>Our Team</h2>
            <p>
              Our team consists of experienced photographers and camera
              enthusiasts who are dedicated to helping you find the perfect
              camera and accessories. We are here to provide expert advice and
              support every step of the way.
            </p>
            <Button variant="danger" className="mt-3">
              Meet the Team
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
