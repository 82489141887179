import React from 'react';
import './StatsSection.css';
import icon  from "../../Assesst/Images/gears-solid.svg"
import icon1  from "../../Assesst/Images/thumbs-up-solid.svg"
import icon2  from "../../Assesst/Images/user-plus-solid.svg"
import icon3  from "../../Assesst/Images/award-solid.svg"

const stats = [
  {
    id: 1,
    icon: icon,
    count: 500,
    label: 'ROUTE NETWORK',
  },
  {
    id: 2,
    icon: icon1,
    label: 'HAPPY CONSUMERS',
  },
  {
    id: 3,
    icon: icon2,
    count: 15000,
    label: 'EXPERIENCED STAFF',
  },
  {
    id: 4,
    icon: icon3,
    count: 100,
    label: 'AWARDS',
  },
];

const StatsSection = () => {
  return (
    <div className="stats-section">
      {stats.map((stat) => (
        <div key={stat.id} className="stat-item">
          <img src={stat.icon} alt={stat.label} className="stat-icon" />
          <h2>{stat.count}</h2>
          <p>+ {stat.label}</p>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;