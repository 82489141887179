
import React from 'react';
import './Contactcard.css';

const ContactCard = () => {
  return (
    <section className="contact-section">
      <div className="contact-container">
        <h1>Get Awesome Experience With Our Responsive Customer Support</h1>
        <p>
          The Undertaking has also undertaken schemes to computerize the
          Consumers and Commercial departments of the Electric Supply Division
          and to decentralize most of the consumer/commercial services at all
          levels.
        </p>
        <button className="contact-button">CONTACT US ➔</button>
      </div>
    </section>
  );
};

export default ContactCard;
