import React from 'react';
import './Footer.css';
import logo from "../../Assesst/Images/logo.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left">
          <img src={logo} alt="Logo" className="logo" />
          <p>
            The Camera service covers the entire city and also extends
            its operations outside city limits into neighbouring urban areas.
          </p>
          <address>
            Sec 63 Noida
            <br />
            <a href="tel:1862565320">1862565320</a>
            <br />
            <a href="mailto:info@example.com">info@example.com</a>
            <br />
          </address>
        </div>
        <div className="footer-right">
          <div className="footer-column">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#">About Organisation</a></li>
              <li><a href="#">Camera</a></li>
              <li><a href="#">Product</a></li>
              <li><a href="#">Why Best</a></li>
              <li><a href="#">Tender</a></li>
              <li><a href="#">Recruitments</a></li>
              <li><a href="#">Contact</a></li>
              <li><a href="#">Others</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Other Links</h3>
            <ul>
              <li><a href="#">FAQ's</a></li>
              <li><a href="#">Mail Login</a></li>
              <li><a href="#">Key Person</a></li>
              <li><a href="#">Who's Who</a></li>
              <li><a href="#">RTI2005</a></li>
              <li><a href="#">Terms and Conditions</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Waiting list for Allotment of staff Quarters</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Our Location</h3>
            <div className="footer-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.664264693445!2d77.372238815368!3d28.56906698246657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf7e5a1f3f893%3A0x8f7a6d7e34b7b!2sSec%2063%2C%20Noida%2C%20Uttar%20Pradesh%20201007%2C%20India!5e0!3m2!1sen!2sus!4v1625079055102!5m2!1sen!2sus"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Map"
              ></iframe>
            </div>
            <address>
              Sec 63 Noida
              <br />
              <a href="tel:1862565320">1862565320</a>
              <br />
              <a href="mailto:info@example.com">info@example.com</a>
              <br />
            </address>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; BEST Undertaking, 2023 | Designed by : <a href="#">ACS Consulting</a></p>
        <div className="social-media">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="digital-navbar-icon">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="digital-navbar-icon">
          <i className="fab fa-youtube"></i>
        </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
