import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <div className="digital-navbar">
      <span className='design-style'></span>
     <div>
      <div className="digital-navbar-left">
        <span className="digital-navbar-location">
          <i className="fas fa-map-marker-alt"></i> digitalinfotech
        </span>
      </div>
      </div>
      <div className="digitalinfotech-navbar-right">
      <div className="digital-navbar-right">
        <span className="digital-navbar-follow-us">Follow Us:</span>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="digital-navbar-icon">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="digital-navbar-icon">
          <i className="fab fa-youtube"></i>
        </a>
        <a href="mailto:example@example.com" className="digital-navbar-icon">
          <i className="fas fa-envelope"></i>
        </a>
        <span className="digital-navbar-language">English</span>
      </div>
      </div>
    </div>
  );
}

export default Navbar;
