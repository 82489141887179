import React from 'react';
import './HomeContentSection1.css';
import icon1 from '../../Assesst/Images/1.gif'; 
import icon2 from '../../Assesst/Images/1.gif'; 
import backgroundImage from '../../Assesst/Images/2.jpg';

const HomeContentSection1 = () => {
  return (
    <div className="city-section">
      <div className="city-section-image">
        <img src={backgroundImage} alt="Background" />
      </div>
      <div className="city-section-content">
        <h4 className='digi-content-heading'>Digital Infotech</h4>
        <h1>We Are <span className="highlight-text2">Heart Of The Cameras</span> That Never Sleeps</h1>
        <p>The Undertaking has a well-equipped Training Center for the traffic staff and designed program to re-educate them on such relevant topics as fuel conservation, safety measures, behaviour with the public etc.</p>
        <div className="features">
          <div className="feature">
            <img src={icon1} alt="Safest Mode of City Transport" />
            <div>
              <h3>Safest Mode of City Transport</h3>
              <p>Electric transport produces zero tailpipe emissions, contributing to cleaner air and healthier urban environments.</p>
            </div>
          </div>
          <div className="feature">
            <img src={icon2} alt="Wide Network of Electric Supply" />
            <div>
              <h3>Wide Network of Electric Supply</h3>
              <p>The wide network of electric supply forms the intricate backbone that powers our modern world.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContentSection1;
