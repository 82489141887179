import './App.css';

import Navigation from './Component/Navigation';




function App() {


  return (
   <>
<Navigation/>
   </>
  );
}

export default App;
