import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutUs from "./AboutUs/AboutUs";
import Home from "./Home/Home";
import Product from "./Product/Product";
import Brochure from "./Brochure/Brochure";
import Client from "./Client/Client";

function Navigation() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product" element={<Product />} />
        <Route path="/brochure" element={<Brochure />} />
        <Route path="/client" element={<Client />} />
      </Routes>
    </Router>
  );
}

export default Navigation;
