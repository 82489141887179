import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sidebar.css"; // Import the CSS file

const Sidebar = () => {
  const products = [
    { name: "CCTV Cameras", link: "#cctv-cameras" },
    { name: "Telephone Sets", link: "#telephone-sets" },
    { name: "Digital EPABX", link: "#digital-epabx" },
    { name: "Analog PBX", link: "#analog-pbx" },
    { name: "DVR", link: "#dvr" },
    { name: "Biometrics", link: "#biometrics" },
    { name: "VDP", link: "#vdp" },
    { name: "Hardware & Networking", link: "#hardware-networking" },
  ];

  return (
    <div className="sidebar">
      <h2>Products</h2>
      <ul>
        {products.map((product, index) => (
          <li key={index}>
            <a href={product.link}>{product.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
