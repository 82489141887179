import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import "./Client.css";
import client1 from "../../Assesst/Images/1.gif"; // Import the CSS file

const clients = [
  { src: client1 },
  { src: "/path/to/image2.jpg", alt: "Client 2" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  { src: "/path/to/image3.jpg", alt: "Client 3" },
  // Add all other client images here
];

const Client = () => {
  return (
    <div className="clients-page">
      {/* Banner Section */}
      <div className="banner">
        <h1>Clients</h1>
      </div>

      {/* Products Section */}
      <Container className="products-section mt-5 mb-4"></Container>

      {/* Clients Section */}
      <Container className="clients-section mt-5">
        <Row>
          {clients.map((client, index) => (
            <Col key={index} xs={6} sm={4} md={3} lg={2} className="mb-4">
              <Image
                src={client.src}
                alt={client.alt}
                fluid
                className="client-logo"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Client;
